/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andri Samsuri
==============================================================================================*/
@import "sass-lib";

/*@media all and (max-width: 1360px) {*/
@media all and (max-width: 1360px) {
	.section-home {
		.row-box {
			&.styled-1 {
				height: 590px;
				.wrapper {padding-top: 190px;} 
			} 
			&.styled-2 {height: 467px;}
			&.styled-3 {height: 438px;}
		}
	} 
}

@media all and (max-width: 1200px) { 
	.section-home {
		.row-box {
			&.styled-1 {
				height: 548px;
				.wrapper {padding-top: 190px;} 
			} 
			&.styled-2 {height: 428px;}
			&.styled-3 {height: 438px;}
		}
		&.section-3 {
			&.space {padding-bottom: 158px;} 
		}
	} 
	.float_ico {
		right: -5px;
		img {width: 140px; }
	}
	@keyframes bounching {
	    0% { bottom: 363px; }
	    25% { bottom: 367px; }
	    50% { bottom: 363px;}
	    75% { bottom: 367px; }
	    100% { bottom: 363px; }
	}
}

@media all and (max-width: 1024px) {

	.wrapper {width: 920px;} 
	.homepage header .wrapper, .homepage footer .wrapper {width: 1024px;}  

	.selectbox {min-width: 160px;}  

	.btn {
		&.btn-primary {
			&.md {font-size: 15px; margin: 0 5px;} 
		}
		&.btn-catalog {
			margin-right: 10px;
			&:last-child {margin-right:0;}  
		} 
	}
	.btn-play {
		width: 70px; height: 70px;
	}
	.btn-play-sm {} 

	header {
		.wrapper {margin-top: 40px;} 
		.burger-menu {
			display: block; position: absolute; width: 40px; height: 29px; /*top: 35px; left: 60px;*/   
			cursor: pointer; 
			top: 20px; left: 40px;
			span {
	            position: absolute; width: 100%; height: 5px; background: #19515f; left: 0; 
	            @include borderRadius(3px); 
	            &:nth-child(1){
	                top:0;
	                @include transformorigin(left center);
	            }
	            &:nth-child(2){
	                top:12px;
	                @include transformorigin(left center);
	            }
	            &:nth-child(3){   
	                top:24px; width: 80%; 
	                @include transformorigin(left center);  
	            }   
	            @include transform(rotate(0deg)); 
	            @include transition( .25s ease-in-out);  
	        }
		}
		.logo {z-index: 5;} 
		.left {display: none;} 
		.right {			
			&.box-other {
				padding-right: 35px;
				&:before {display:none;}   
				&:after {
					position:absolute; width: 400px; height: 100%; background: #fff; content: ""; 
					right: 0; top: 0; z-index: -1; 
					@include borderRadius(5px 5px 5px 5px);  
				}
				.box-lang {display: none;} 
			}
		}
	}

	.slick-dots {
		li {
			button {border:0; width: 12px; height: 12px;}  
		}
	}

	.homepage {
		.wrapper {} 
		header, footer {
			.wrapper {width: 920px;}      
		}	
		.btn-play {
			width: 70px; height: 70px;
		}	
	}
	.contentpage {
		 header {
		 	.wrapper {width: 920px;} 
		 	.burger-menu {left: 0;}  
		 	.right {			
				&.box-other {					  
					&:after {						 
						@include borderRadius(0);   
					}					
				}
			}
		 }
	}
	.contentpage2 {
		.wrapper {width: 920px;} 
		header {
			.wrapper {width: 920px;} 
		}
	}

	.middle {min-height: 300px;} 
	.section-home {
		&.section-1 {background-size: 119%;} 
		&.section-2 {background-size: 119%;} 
		&.section-3 {
			background-size: 119%;
			&.space {padding-bottom: 185px;}  
			&:after {display: block;} 
		} 

		.row-text {
			height: 220px;  
			.wrapper {padding-top: 50px;} 
			.right {
				padding-top: 50px; 
			}  			
		}

		.row-box {
			&.styled-1 {
				background-size: 103%; height: 520px;
				.wrapper {padding: 200px 0 0 0;}
			}     
			&.styled-2 {
				background-size: 103%; height: 411px;
				.wrapper {padding: 105px 0 0 0;} 
			}  
			&.styled-3 { 
				background-size: 103%; height: 440px; 	
				.wrapper {padding: 125px 0 0 0;} 			
			}  
		}
	}

	.banner-page {
		figure {
			height: 475px; 
			img {width: 100%; height: 100%; object-fit: cover;} 
		}
	}

	.ribbon {
		&.relative {
			padding: 0 10px; 
			&.mg-left {margin-left: 20px;}  
		}
	}

	.row-main {
		.article {
			p {line-height: 24px;} 
			ul {
				li {
					line-height: 25px; 
					&:before {width:8px; height: 8px; top: 9px; line-height: 7px;}   
				}
			}
			ol { 
				li {
					line-height: 25px; 
					&:before {width:8px; height: 8px; top: 9px;} 
				}
			}
		} 
		.side {
			width: 19%;
			&.sd-right {
				padding: 20px;  
				h3 {font-size: 18px;} 
			}
		}
		.main-content {
			width: 81%; padding-left: 35px;
			&.article {padding-left: 0;} 
			h1 {
				font-size: 20px; 
			}
			.top-mid { 
				margin-bottom: 25px; 
				.cl {
					padding: 0 0 0 0; margin: 0 0 0 6px; border: 0;   
				}
			}
			.main-filter { 
				&.no-border {margin-bottom: 25px;}  
			}
			.if-youtube {
				/*iframe {height: 325px !important;} */
			}
		}
		.notfound {
			&.article {width: 350px; padding: 30px 0 0 0;}   
		}
		&.rm-2 {
			.wrapper {width: 920px;}
		}
	}
	.sidebar-filter {
		&.is-video {
			.filter-video { 
				a {
					p {} 
				}
			}
		}
	}
	.watermark {
	    width: 75px; height: 28px;   
	}
	.box-large {
		margin-bottom: 30px; 
		&.md {
			.col {
				&.text {padding-left: 30px;}  
				&.figure {
					.thumbSlide {
						.slick-list {margin: 0 20px;} 
						.slide {width: 83px; height: 83px;} 
						.slick-arrow {
	                        &.slick-next {right: -10px;}  
	                        &.slick-prev {left: -10px;}  
	                    }
					}
					.productSlide {margin-bottom: 25px;} 
				}
			}
		}
		&.event {
			.figure {width: 39%; height: 315px;} 
			.text {width: 61%;} 
			&.event-2 {
				.row {margin-bottom: 20px;}  
				.figure {height: 215px;}  
			}
			.row.full {
				padding: 30px 90px;
			}
		}		
		.retailSlider {  
			figure {
				height: 393px;   
			}
		}
	}
	.frame-listing { 
		.list-item {
			margin: 0 2% 3% 0; width: 32%;  
			figure {height: 195px;} 
			.text {
				.cate {
					line-height: 17px;
					span {margin-right: 4px; padding-right: 6px;}
				}
			}
			&.hv_vote {
				.text {
					.short-descrip {padding-top: 25px;}
					.flag {
						width: 40%;
						.vote-ico,
						.vote {display: inline-block;}
						.ribbon.vote {}
						.ribbon {
							margin-right: 0 !important;
							&:last-child{margin-top: 8px;}
							&:first-child{margin-top: 0px;}
						}
					}
				}
			}
		}
		&.col-2 {padding: 40px 0 0 0;}  
		&.other {
			padding: 35px 30px;     
		}
		&.medium { 
			.list-item { 
				margin-bottom: 20px; 
				figure {height: 130px;}    
			}
		}
	}
	.product-list { 
		.list-item {
			width: 33.333%;  
		}
		&.other-list {
			.list-item {
				display: none; 
				&:nth-child(1) {display: inline-block;}
				&:nth-child(2) {display: inline-block;}
				&:nth-child(3) {display: inline-block;}
			}
		}
	}
	.box-share {
		.cl {
			a {margin: 0 1px;}
		}
	}
	.box-cate-item { 
		padding: 18px 18px 10px 18px; 
		.item {}   
	}
	.row-certificate {
		.col { 
			&:last-child {padding-right:0;}  
		}
		.row {
			.list {
				margin: 0 8px 0 0; vertical-align: middle; float: none; display: inline-block;				
			}			
		}
	}
	.retail-listing { 
		.list-item { 
			width: 47%; margin: 0 3% 3% 0; padding: 0 20px 0 0;     
		}
	}
	.box-address {
		padding: 20px 25px; 
		&.v2 {
			padding: 20px;  
			.row {
				padding: 0 0 0 13px;
				&:before {width:9px; height:+ 9px; top: 9px;}  
			}
		}
		&.col-2 {
			.row {padding-right: 10px;} 
		} 
	}
	.text-listing {  
		padding: 35px 45px;
	}
	.row-event {
		padding: 50px 50px;
		.part-bottom {
			padding: 20px 30px;
			.cl {
				&.left {
					width: 50%;
					p {font-size: 16px;} 
				}
				&.right {width: 50%;} 
			}
			&.v2 {
				padding: 20px 30px;
			}
		}
		&.ev-pets {
			padding: 50px; 
			.box-large {
				&.event {
					&.event-2 {
						.row {
							margin-bottom: 40px; padding-bottom: 40px;   
							&:last-child {margin-bottom:0;} 
						}
					}
				}
			}
		}
	}

	.row-about { 
		.col {
			&.text {padding: 10px 0 0 40px;}  
		}
	}

	.std { 
		.fm-search {
			input[type="text"] {min-width: 110px;width: 110px;}      
		}
	}

	.row-404 {
		height: 460px;
	}

    
}
